"use strict";
import {
  ApiConfig,
  ClassStartData,
  VHSEventDetails,
  VHSEventType,
  VHSHeartbeat,
  VideoStartData,
} from "types";
import "./index.css";

const context = cast.framework.CastReceiverContext.getInstance();
const playerManager = context.getPlayerManager();

const LOG_RECEIVER_TAG = "Receiver";

// ****************************************
// ****************************************
// *******         DEBUGGER        ********
// ****************************************
// ****************************************

/**
 * Debug Logger
 */
const castDebugLogger = cast.debug.CastDebugLogger.getInstance();

/**
 * WARNING: Make sure to turn off debug logger for production release as it
 * may expose details of your app.
 * Uncomment below line to enable debug logger and show a 'DEBUG MODE' tag at
 * top left corner.
 */
// castDebugLogger.setEnabled(true);

/**
 * Uncomment below line to show debug overlay.
 */
// castDebugLogger.showDebugLogs(true);

/**
 * Set verbosity level for Core events.
 */
castDebugLogger.loggerLevelByEvents = {
  "cast.framework.events.category.CORE": cast.framework.LoggerLevel.INFO,
  "cast.framework.events.EventType.MEDIA_STATUS":
    cast.framework.LoggerLevel.DEBUG,
};

if (!castDebugLogger.loggerLevelByTags) {
  castDebugLogger.loggerLevelByTags = {};
}

/**
 * Set verbosity level for custom tag.
 * Enables log messages for error, warn, info and debug.
 */
castDebugLogger.loggerLevelByTags[LOG_RECEIVER_TAG] =
  cast.framework.LoggerLevel.DEBUG;

// ****************************************
// ****************************************
// *******   VHS EVENT TRACKING   *********
// ****************************************
// ****************************************

// VHS CALL VARIABLES
let VHS_API_CONFIG: ApiConfig = {};
let CLASS_DATA: ClassStartData | null = null;
let TOKEN: string = "";
const VHS_INTERVAL = 30;

let nextPingDate: Date | null = null;

// CHROME EVENT -> VHS EVENT TYPE
const EVENT_MAP: { [key: string]: VHSEventType } = {
  LOADED_DATA: "start",
  MEDIA_FINISHED: "stop",
  PLAY: "play",
  PAUSE: "pause",
  TIME_UPDATE: "heartbeat",
};

// Build VHS event from chromecast event
const sendVHSEvent = (event: any, userGenerated: boolean): void => {
  const { currentMediaTime, type } = event;
  const eventDetails = userGenerated ? "user generated" : "system generated";
  const durationSeconds = CLASS_DATA?.video?.metadata?.duration ?? 0;
  const data = {
    id: CLASS_DATA?.videoProgress ? CLASS_DATA?.videoProgress[0]?.id : "",
    durationSeconds,
    eventDetails: eventDetails as VHSEventDetails,
    eventType: EVENT_MAP[type],
    timeValueSeconds: currentMediaTime,
  };

  makeVhsRequest(data);
};

// Send event to VHS
const makeVhsRequest = (data: VHSHeartbeat) => {
  const { id, timeValueSeconds, durationSeconds, eventType, eventDetails } =
    data;

  const [[vhsHeaderKey, vhsHeaderValue]] = VHS_API_CONFIG.headers;

  const body = {
    heartbeatInterval: VHS_INTERVAL,
    guid: id,
    timecode: Math.round(timeValueSeconds),
    timestamp: new Date().toISOString(),
    platformDetails: "chromecast_device",
    platform: "chromecast",
    eventType,
    tealiumDataSourceKey: "zca5n0",
    tealiumVisitorId: "chromecast app",
    eventDetails,
    durationSeconds,
  };
  const bodyStr = JSON.stringify(body);
  console.log("VHS", body);

  try {
    return fetch(`${VHS_API_CONFIG.baseUrl}/heartbeats`, {
      method: "POST",
      headers: {
        [vhsHeaderKey]: vhsHeaderValue,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
      body: bodyStr,
    });
  } catch (e) {
    // logException("Error hitting VHS", e);
  }
};

// set time of next heartbeat
const restartHeartbeats = (): void => {
  const nextDate = new Date();
  nextDate.setSeconds(nextDate.getSeconds() + VHS_INTERVAL + 1);
  nextPingDate = nextDate;
};

// stop scheduled heartbeat events
const clearHeartbeats = (): void => {
  nextPingDate = null;
};

// VHS "STOP" event listener
playerManager.addEventListener(
  cast.framework.events.EventType.MEDIA_FINISHED, /// could also be .ENDED ?
  (event) => {
    sendVHSEvent(event, false);
    context.stop();
  }
);

// Schedule next heartbeat event at end of seeking
playerManager.addEventListener(
  cast.framework.events.EventType.SEEKED,
  (event) => {
    restartHeartbeats();
  }
);

// Clear next heartbeat event on active seek
playerManager.addEventListener(
  cast.framework.events.EventType.SEEKING,
  (event) => {
    clearHeartbeats();
  }
);

// VHS "PLAY" event listener
playerManager.addEventListener(
  cast.framework.events.EventType.PLAY,
  (event) => {
    sendVHSEvent(event, true);
  }
);

// VHS "PAUSE" event listener
playerManager.addEventListener(
  cast.framework.events.EventType.PAUSE,
  (event) => {
    sendVHSEvent(event, true);
    clearHeartbeats();
  }
);

// VHS "START" event listener
playerManager.addEventListener(
  cast.framework.events.EventType.LOADED_DATA,
  (event) => {
    restartHeartbeats();
    sendVHSEvent(event, false);
  }
);

// VHS "HEARTBEAT" event listener
playerManager.addEventListener(
  cast.framework.events.EventType.TIME_UPDATE,
  (event) => {
    const now = new Date();
    if (nextPingDate && nextPingDate < now) {
      sendVHSEvent(event, false);
      restartHeartbeats();
    }
  }
);

// ****************************************
// ****************************************
// *******       LOAD MEDIA       *********
// ****************************************
// ****************************************

/**
 * Obtains media from a remote repository.
 * @param  {String}  _id openfit class _id to be queried
 * @param  {String}  _key openfit class _key to be queried
 * @param  {String}  authToken user's auth token
 * by CONTENT_URL.
 * @return {Promise} Contains the media information of the desired entity.
 */
const fetchMediaById = (
  _id: string,
  _key: string,
  authToken: string
): Promise<VideoStartData> => {
  castDebugLogger.debug(LOG_RECEIVER_TAG, "fetching id: " + _id);
  const BFF_API_URL = window.config["BFF_API_URL"];

  return new Promise((accept, reject) => {
    fetch(BFF_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        query: `
          query ClassStartInfo($_id: ID!, $_key: String) {
            classStartInfo(_id: $_id, _key: $_key) {
              playbackServices {
                name,
                baseUrl
                headers
              }
              videoProgress {
                id
                percentComplete
              }
              video {
                urls {
                  default
                  playbackRays
                smil
                  playbackStandard
                }
                vhsApi {
                  baseUrl
                  headers
                }
                metadata {
                  description
                  duration
                  guid
                  pid
                  program
                  resumePoint
                  title
                  type
                  artists {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
            `,
        variables: {
          _id: _id,
          _key: _key,
        },
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        const videoData = result?.data?.classStartInfo?.video;
        const playbackServices = result?.data?.classStartInfo?.playbackServices;
        if (playbackServices) {
          VHS_API_CONFIG = playbackServices[1];
          CLASS_DATA = result?.data?.classStartInfo;
          TOKEN = authToken;
        }
        if (videoData) {
          accept(videoData);
        } else {
          reject("Class video info not found");
        }
      });
  });
};

const ID_REGEX = "/?([^/]+)/?$";
/**
 * Intercept the LOAD request to load and set the contentUrl and add ads.
 */
playerManager.setMessageInterceptor(
  cast.framework.messages.MessageType.LOAD,
  async (loadRequestData): Promise<any> => {
    castDebugLogger.debug(
      LOG_RECEIVER_TAG,
      `loadRequestData: ${JSON.stringify(loadRequestData)}`
    );

    // If the loadRequestData is incomplete return an error message
    if (!loadRequestData || !loadRequestData.media) {
      const error = new cast.framework.messages.ErrorData(
        cast.framework.messages.ErrorType.LOAD_FAILED
      );
      error.reason = cast.framework.messages.ErrorReason.INVALID_REQUEST;
      return error;
    }

    // check all content source fields for asset URL or ID
    let source =
      loadRequestData.media.contentUrl ||
      loadRequestData.media.entity ||
      loadRequestData.media.contentId;

    // If there is no source or a malformed ID then return an error.
    if (!source || source == "" || !source.match(ID_REGEX)) {
      let error = new cast.framework.messages.ErrorData(
        cast.framework.messages.ErrorType.LOAD_FAILED
      );
      error.reason = cast.framework.messages.ErrorReason.INVALID_REQUEST;
      return error;
    }

    let sourceId = source.match(ID_REGEX)?.[1];

    if (!sourceId) {
      return;
    }

    // Fetch the contentUrl if provided an ID or entity URL
    castDebugLogger.debug(LOG_RECEIVER_TAG, "Interceptor received ID");
    return fetchMediaById(
      loadRequestData.media.customData.classID,
      loadRequestData.media.customData.classKey,
      loadRequestData.media.customData.authToken
    )
      .then((item) => {
        let metadata = new cast.framework.messages.GenericMediaMetadata();
        metadata.title = item?.metadata?.title ?? "";
        metadata.subtitle = item?.metadata?.description ?? "";
        loadRequestData.media.contentId = item?.urls?.default ?? "";

        loadRequestData.media.metadata = metadata;
        return loadRequestData;
      })
      .catch((error) => console.warn("Could not load media:", error))
      .then((loadRequestData) => {
        return loadRequestData;
      });
  }
);

const playbackConfig = new cast.framework.PlaybackConfig();

/**
 * Set the player to start playback as soon as there are five seconds of
 * media content buffered. Default is 10.
 */
playbackConfig.autoResumeDuration = 5;
castDebugLogger.info(
  LOG_RECEIVER_TAG,
  `autoResumeDuration set to: ${playbackConfig.autoResumeDuration}`
);

/**
 * Set the control buttons in the UI controls.
 */
const controls = cast.framework.ui.Controls.getInstance();
controls.clearDefaultSlotAssignments();

/**
 * Assign buttons to control slots.
 */
controls.assignButton(
  cast.framework.ui.ControlsSlot.SLOT_SECONDARY_1,
  cast.framework.ui.ControlsButton.QUEUE_PREV
);
controls.assignButton(
  cast.framework.ui.ControlsSlot.SLOT_PRIMARY_1,
  cast.framework.ui.ControlsButton.CAPTIONS
);
controls.assignButton(
  cast.framework.ui.ControlsSlot.SLOT_PRIMARY_2,
  cast.framework.ui.ControlsButton.SEEK_FORWARD_15
);
controls.assignButton(
  cast.framework.ui.ControlsSlot.SLOT_SECONDARY_2,
  cast.framework.ui.ControlsButton.QUEUE_NEXT
);

context.start({
  playbackConfig: playbackConfig,
  supportedCommands:
    cast.framework.messages.Command.ALL_BASIC_MEDIA |
    cast.framework.messages.Command.QUEUE_PREV |
    cast.framework.messages.Command.QUEUE_NEXT |
    cast.framework.messages.Command.STREAM_TRANSFER,
});
